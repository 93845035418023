<template xmlns="http://www.w3.org/1999/html">
  <div class="flex flex-col">
    <span class="mt-3 font-bold	">Dateien</span>
    <div class="flex flex-col my-1 mr-5 w-80 max-h-40 overflow-y-scroll border border-gray-400">
      <ul>
        <li v-for="fileKey in $store.state.apiData.fileList" class="flex text-sm border border-gray-400 hover:bg-gray-300 text-left cursor-pointer p-2">
          <div @click="storage().loadFile(fileKey)">
            <font-awesome-icon icon="fa-file" class="pr-3" />
            <span class="mr-3 break-all">{{ fileKey.split("/").slice(-1)[0] }}</span>
          </div>
          <span @click="storage().deleteFile(selectedYear, selectedMonth, selectedSSP, fileKey.split('/').slice(-1)[0])">
            <font-awesome-icon icon="fa-trash-can" class="hover:text-red-400"/>
          </span>
        </li>
      </ul>
      <label for="fileDrop" @dragover="dragover" @dragleave="dragleave" @drop="drop" class="flex text-sm border border-gray-400 hover:bg-gray-300 cursor-copy p-2">
        <font-awesome-icon icon="fa-upload" class="pr-2" />
        <span>Datei hochladen</span>
        <input @change="storage().uploadFile(selectedYear, selectedMonth, selectedSSP, getUploadedFileName())" class="hidden" type="file" id="fileDrop"/>
      </label>
    </div>
  </div>
</template>

<script>
import storage from "@/js/storage";
export default {
  name: "FileView",
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  mounted() {
    this.$watch(
        () => [this.selectedYear, this.selectedMonth, this.selectedSSP], (toParams) => {
          storage.loadFileData(this.selectedYear, this.selectedMonth, this.selectedSSP)
        }
    )
    storage.loadFileData(this.selectedYear, this.selectedMonth, this.selectedSSP)
  },
  methods: {
    storage() {return storage},
    getUploadedFileName() {
      return document.getElementById('fileDrop').files[0]
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-200');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      // start upload
      let item = event.dataTransfer.items[0];
      if (item.kind === 'file') {
        const file = item.getAsFile();
        storage.uploadFile(this.selectedYear, this.selectedMonth, this.selectedSSP, file)
      }
      // Reset drop zone
      event.currentTarget.classList.add('bg-gray-200');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
}
</script>