<template xmlns="http://www.w3.org/1999/html">
  <div class="flex flex-col">
    <span class="mt-3 font-bold">Aktionen</span>
    <div class="flex flex-col rounded-sm my-1 mr-5 w-80 max-h-40 overflow-y-scroll border border-gray-400">
      <ul>
        <li @click="action.callback(this)" v-for="action in actions" class="text-sm border border-gray-400 hover:bg-gray-300 cursor-pointer text-left p-2">
          <font-awesome-icon icon="fa-chevron-right" class="pr-1" />{{ action.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from "@/js/api";
import Alert from "@/components/reusable/Alert.vue";
import store from "@/store";
export default {
  name: "ActionView",
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  emits: ['openOverlay'], // required because we emit from callback
  data() {
    return {
      kaboom: false,
      actions: [{
        label: "Belegwert eingeben",
        callback: function(vm) {
          vm.$emit("openOverlay")
        }
      }, {
        label: "Umsatzdifferenz freigeben",
        callback: async function(vm) {
          if (!confirm("Bist du sicher, dass du die Umsatzdifferenz als korrekt freigeben willst?")) return;
          // TODO: post timeline event
          await api.resolveState(
            vm.selectedYear, vm.selectedMonth, vm.selectedSSP, "Umsatzabgleich"
          ).then(function() {
            vm.$store.commit("showSuccessAlert", "Umsatzdifferenz wurden als freigegeben markiert!")
          }).catch(function(error) {
            console.error(error)
            vm.$store.commit("showErrorAlert", "Umsatzdifferenz konnte nicht freigegeben werden!")
          })
          await api.resolveState(
              vm.selectedYear, vm.selectedMonth, vm.selectedSSP, "Umsatzkorrektur"
          ).catch(function(error) {
            console.error(error)
            vm.$store.commit("showErrorAlert", "Umsatzdifferenz konnte nicht freigegeben werden!")
          })
          await api.getStates()
        }
      },{
        label: "Fehlende PowerBI-Umsätze freigeben",
        callback: async function(vm) {
          if (!confirm("Bist du sicher, dass du die PowerBI-Umsätze als korrekt freigeben willst?")) return;
          // TODO: post timeline event
          await api.resolveState(
            vm.selectedYear, vm.selectedMonth, vm.selectedSSP, "Umsätze in PowerBI"
          ).then(function() {
            vm.$store.commit("showSuccessAlert", "PowerBI-Umsätze wurden als vollständig markiert!")
          }).catch(function(error) {
            vm.$store.commit("showErrorAlert", "Fehlende PowerBI-Umsätze konnten nicht freigegeben werden!")
          })
          await api.getStates()
        }
      }],
    }
  },
  methods: {
    api() {return api}
  }
}
</script>