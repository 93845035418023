<template>
  <div class="h-[calc(100vh-60px)] flex z-30 hover:-transition-transform duration-1000 ease-in-out" :class="menuWidth">
    <div class="mx-2 mb-2 mt-2 pt-2 pr-2 flex-grow border-r border-t border-b border-gray-400 rounded-r-md overflow-y-scroll scrollbar-hide">
      <!-- Expanded Menu Header -->
      <transition name="header">
        <div v-if="menuIsExpanded" class="ml-[calc(18rem+3.5px)] w-[calc(100vw-20rem-75px)] flex h-14 2xl:h-10 text-sm">
          <div v-for="stage in $store.getters.getStages()" class="border border-gray-400 mx-1 my-1 px-4 py-1 w-64 text-gray-700">
            {{ stage.acsg_label }}
          </div>
        </div>
      </transition>

      <!-- Always visible SideMenu -->
      <div v-for="ssp in $store.getters.getSellingSidePlatformsForMonth(selectedYear, selectedMonth)" class="flex min-w-fit">
        <div class="w-72 border border-gray-400 rounded-sm my-0.5">
          <div @click="openSSPDetail(ssp.ssp_name)" :class="selectedSSP === ssp.ssp_name ? 'bg-gray-400' : ''" class="py-2 px-4 flex items-center rounded-sm duration-300 cursor-pointer hover:bg-gray-400 text-sm font-bold">
            <font-awesome-icon v-if="isSSPFullyResolved(ssp)" icon="fa-circle-check" class="text-green-500"/>
            <font-awesome-icon v-else icon="fa-circle-xmark" class="text-red-500"/>
            <div class="flex justify-between w-full items-center">
              <span class="mx-2 text-gray-700">{{ ssp.ssp_name }}</span>
            </div>
          </div>
        </div>

        <!-- Expanded Menu States -->
        <transition name="states">
          <div v-if="menuIsExpanded" class="flex w-[calc(100vw-20rem-75px)] ml-1">
            <div v-for="state in $store.getters.getStates(selectedYear, selectedMonth, ssp.ssp_cntr)" class="border border-gray-400 mx-1 my-0.5 px-4 py-1 flex-grow">
              <font-awesome-icon v-if="state.acst_isresolved" icon="fa-circle-check" class="text-green-500 text-lg align-middle" />
              <font-awesome-icon v-else icon="fa-circle-xmark" class="text-red-500 text-lg align-middle" />
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- Menu Expander - I wanted to expand on hover but Anne insisted to do it on click, this is all her fault -->
    <div @click="menuIsExpanded=!menuIsExpanded" class="hover:bg-gray-300 relative h-16 w-8 left-[-9px] top-[calc(50vh-7.5rem)] bg-gray-200 border-b border-r border-t border-gray-400 rounded-r-md">
      <font-awesome-icon v-if=!menuIsExpanded icon="fa-chevron-right" class="text-xl py-5 pl-1 pr-2" />
      <font-awesome-icon v-else icon="fa-chevron-left" class="text-xl py-5 pl-1 pr-2" />
    </div>
  </div>
</template>

<script>
import DropDown from "@/components/reusable/DropDown.vue";
import api from "@/js/api";

export default {
  name: "SideMenu",
  components: [DropDown],
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  data() {
    return {
      menuIsExpanded: false,
    }
  },
  computed: {
    menuWidth() {
      return this.menuIsExpanded ? "w-screen" : "w-[350px]"
    }
  },
  methods: {
    api() {return api},
    openSSPDetail(sspName) {
      this.$router.push("/" + this.selectedYear  + "/" + this.selectedMonth + "/" + sspName.replace("/", "%2F"))
      this.menuIsExpanded = false
    },
    isSSPFullyResolved(ssp) {
      let states = this.$store.getters.getStates(this.selectedYear, this.selectedMonth, ssp.ssp_cntr)
      if (states !== undefined) {
        return states.every(item => item.acst_isresolved)
      } else {
        return false
      }
    },
  }
}
</script>

<style scoped>
.states-enter-active, .states-leave-active {
  transition: opacity 1s ease;
}
.states-enter-from, .states-leave-to {
  opacity: 0;
}
.header-enter-active, .header-leave-active {
  transition: opacity 1s ease, height 1s ease;
}
.header-enter-from, .header-leave-to {
  opacity: 0;
  height: 0
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>