<template>
  <div class="flex flex-col 2xl:h-[125px] rounded-sm border border-gray-400 px-2 mr-6 2xl:mx-4 my-2 overflow-y-hidden">
    <ol class="flex flex-wrap justify-center divide-gray-200 rounded-lg border border-gray-200 text-sm text-gray-800">
      <li class="w-36 2xl:w-[175px] items-center justify-center py-2 max-w-sm" v-for="(state, index) in statesForSSP">
        <div class="flex">
          <div class="flex-grow">
            <font-awesome-icon v-if="state.acst_isresolved" icon="fa-circle-check" class="text-green-500 text-2xl my-1" />
            <font-awesome-icon v-else icon="fa-circle-xmark" class="text-red-500 text-2xl my-1" />
            <p class="leading-none">
              <strong class="block font-medium">
                {{ $store.getters.getStage(state.acsg_cntr).acsg_label }}
              </strong>
              <div class="mt-1 text-[12px] border-t pt-1 border-gray-400 invisible w-0 h-0 2xl:visible 2xl:w-fit 2xl:h-fit">
                {{ $store.getters.getStage(state.acsg_cntr).acsg_label_long }}
              </div>
            </p>
          </div>
          <font-awesome-icon v-if="index !== statesForSSP.length - 1" icon="fa-chevron-right" class="text-2xl my-4 ml-1" />
          <div v-else class="w-10"></div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import api from "@/js/api";
export default {
  name: "StageView",
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  computed: {
    statesForSSP() {
      return this.$store.getters.getStates(
          this.selectedYear,
          this.selectedMonth,
          this.$store.getters.getSellingSidePlatform(this.selectedSSP)?.ssp_cntr
      )
    }
  },
  async mounted() {
    await api.getStates()
    await api.getStages()
    await api.getSellingSidePlatform()
  }
}
</script>