import {EventType, PublicClientApplication} from "@azure/msal-browser";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import api from "@/js/api";

export default class AuthenticationHandler {
    static msalConfig = {
        auth: {
            clientId: '992cda27-e285-44f4-bb2f-5b79385c0e33',
            authority: 'https://login.microsoftonline.com/78a6b313-ae8f-4324-ba36-85e7b2bc6f1d',
        },
        cache: {
            cacheLocation: 'localStorage',
        },
    }
    static msalInstance = null;
    static account = null;

    static setupMSALInstance() {
        AuthenticationHandler.msalInstance = new PublicClientApplication(AuthenticationHandler.msalConfig);
    }

    static setupLoginHandling(emitter) {
        this.msalInstance.addEventCallback(async function(event) {
            // set active account after redirect
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                console.log("Handling redirect login event...")
                AuthenticationHandler.msalInstance.setActiveAccount(event.payload.account);

                const accounts = AuthenticationHandler.msalInstance.getAllAccounts();
                AuthenticationHandler.account = accounts[0];
                await AuthenticationHandler.getGraphAccessToken();
                await AuthenticationHandler.getStorageToken();
                await AuthenticationHandler.getDatabricksToken();
                await AuthenticationHandler.getUserPhoto();
                await AuthenticationHandler.isAllowedAccess();
                Sentry.setUser({ username: AuthenticationHandler.account.name });
                emitter.emit('login', AuthenticationHandler.account);
            } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
                console.log("Handling redirect logout event...")
                localStorage.clear()
                await AuthenticationHandler.msalInstance["browserStorage"].clear();
                AuthenticationHandler.account = undefined;
                Sentry.setUser(null);
                emitter.emit('logout', 'logging out');
            }
        }, function(error) {
            console.error(`error during authentication: ${error}`);
        });

        this.msalInstance.handleRedirectPromise().then(async function(authResult) {
            console.log("Handling redirect login promise...")
            const accounts = AuthenticationHandler.msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                AuthenticationHandler.account = accounts[0];
                await AuthenticationHandler.isAllowedAccess();
                await AuthenticationHandler.getUserPhoto();
                Sentry.setUser({ username: AuthenticationHandler.account.name });
                emitter.emit('login', AuthenticationHandler.account);
            }
            emitter.emit('ssoLoadComplete'); // regardless of success
        }).catch(function (err) {
            console.log(err);
        })
    }

    static async signIn() {
        let scopes = [
            'https://graph.microsoft.com/User.ReadBasic.All'
        ]
        await AuthenticationHandler.msalInstance.loginRedirect({scopes: scopes, redirectUri: window.location.origin})
    }

    static async signOut() {
        await AuthenticationHandler.msalInstance.logoutRedirect({})
    }

    static async isAllowedAccess(emitter, retry = true) {
        let accessToken = localStorage.getItem("accessToken-storage");
        await axios({
            method: "GET",
            url: api.baseUrl + "/verifyUser",
            headers: {Authorization: 'Bearer ' + accessToken}
        }).then(function(response) {
            if (response.status !== 200) {
                emitter.emit('accessDenied');
            }
        }).catch(async function(error) {
            console.log(error)
            if (retry) {
                await AuthenticationHandler.getStorageToken();
                await AuthenticationHandler.isAllowedAccess(emitter, false)
                return
            }
            emitter.emit('accessDenied');
        })
    }

    static async getGraphAccessToken() {
        let scopes = [
            'https://graph.microsoft.com/User.ReadBasic.All'
        ]
        await AuthenticationHandler.getAccessToken(scopes, "graph")
    }

    static async getDatabricksToken(){ // to start databricks job
        let scopes = [
            '2ff814a6-3304-4ab8-85cb-cd0e6f879c1d/user_impersonation',
        ]
        await AuthenticationHandler.getAccessToken(scopes, "databricks")
    }

    static async getStorageToken(){ // also used for API authentication
        let scopes = [
            'https://storage.azure.com/user_impersonation',
        ]
        await AuthenticationHandler.getAccessToken(scopes, "storage")
    }

    static async getAccessToken(scopes, name){
        let request = {
            scopes: scopes,
            account: AuthenticationHandler.account,
            redirectUri: window.location.origin
        };

        try {
            let tokenResponse = await AuthenticationHandler.msalInstance.acquireTokenSilent(request);
            localStorage.setItem("accessToken-" + name, tokenResponse.accessToken)
            console.log(tokenResponse.accessToken, name)
        } catch (error) {
            console.error( 'Silent token acquisition failed. Using interactive mode' );
            console.log(error)
            let tokenResponse = await AuthenticationHandler.msalInstance.acquireTokenRedirect(request);
            localStorage.setItem("accessToken-" + name, tokenResponse.accessToken)
            console.log("Access token acquired via interactive auth")
        }
    }

    static async getUserPhoto(retry = true) {
        let accessToken = localStorage.getItem("accessToken-graph");
        await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: { Authorization: 'Bearer ' + accessToken},
        }).then(async function(response) {
            if (response.status === 401 && retry) {
                console.warn("Token expired! Requesting new token.")
                await AuthenticationHandler.getGraphAccessToken(true)
                await AuthenticationHandler.getUserPhoto(false)
            } else if (response.status === 200) {
                const pictureBlob = await response.blob();
                document.querySelector('#userPhoto').src = URL.createObjectURL(pictureBlob);

                // store user image for use in requests
                let reader = new FileReader()
                reader.readAsDataURL(pictureBlob)
                reader.onload = () => {
                    let base64Image = reader.result
                    localStorage.setItem("userImage", base64Image);
                };
            }
        })
    }
}