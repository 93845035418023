<template>
  <button v-if="!AuthenticationHandler().account" @click="AuthenticationHandler().signIn" class="text-white text-xs bg-gray-800 hover:bg-gray-900 border-1 border-white py-1 px-4 m-2">Anmelden</button>
  <button v-else @click="AuthenticationHandler().signOut" class="text-white text-xs bg-gray-800 hover:bg-gray-900 border-1 border-white py-1 px-4 m-2">Abmelden</button>
  <div v-show="isLoggedIn" class="flex mt-4 my-2 mr-5 min-w-100">
    <img class="h-8 w-8 ml-4 rounded-full bg-white" id="userPhoto" src="#" alt="User Photo">
    <div class="text-white text-1xl ml-4 mt-1">{{ AuthenticationHandler().account?.name ?? "" }}</div>
  </div>
  <div v-show="!isLoggedIn" class="flex my-2 mr-5 min-w-100">
    <div class="text-white text-1xl ml-5 mt-3">Nicht angemeldet</div>
  </div>
</template>

<script>
import AuthenticationHandler from "@/js/authentication";
export default {
  name: 'UserInfo',
  data() {
    return {
      isLoggedIn: false
    }
  },
  mounted() {
    AuthenticationHandler.setupMSALInstance();
    this.$emitter.on('login', (account) => {
      this.isLoggedIn = true;
    })
    this.$emitter.on('logout', () => {
      this.isLoggedIn = false;
    });
    AuthenticationHandler.setupLoginHandling(this.$emitter)
  },
  methods: {
    AuthenticationHandler() {return AuthenticationHandler}
  },
};
</script>