import store from "@/store";
import convert from "xml-js";
import axios from "axios";
import AuthenticationHandler from "@/js/authentication";
const instance = axios.create({baseURL: 'https://hmgbsapppam.blob.core.windows.net'})

async function loadFileData(selectedYear, selectedMonth, selectedSSP) {
    let accessToken = localStorage.getItem("accessToken-storage");
    store.commit("setIndicateLoading", true)

    selectedSSP = selectedSSP.replace("/", "")
    await instance.get(`/data?prefix=userFiles/${selectedYear}/${selectedMonth}/${selectedSSP}/&showonly=files&restype=container&comp=list`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-ms-version": "2021-08-06",
            //'Cache-Control': 'no-cache',
            //'Pragma': 'no-cache',
            //'Expires': '0'
        }
    }).then(async function(response) {
        let jsonText = convert.xml2json(response.data, {compact: true});
        let oneOrMultipleBlobs = JSON.parse(jsonText).EnumerationResults.Blobs.Blob

        if(oneOrMultipleBlobs === undefined) {
            store.commit("updateFileList", [])
            store.commit("setIndicateLoading", false)
            return
        }
        if (oneOrMultipleBlobs.Name !== undefined) {
            store.commit("updateFileList", [oneOrMultipleBlobs.Name._text])
        } else {
            let fileList = []
            for (let blob of oneOrMultipleBlobs) {
                fileList.push(blob.Name._text)
            }
            store.commit("updateFileList", fileList)
        }
        store.commit("setIndicateLoading", false)
    }).catch(async function(error) {
        console.log(error);
        await AuthenticationHandler.getStorageToken();
        store.commit("setIndicateError", true)
    })
}

async function loadFile(fileKey) {
    return new Promise(async function(resolve, reject) {
        let accessToken = localStorage.getItem("accessToken-storage");
        store.commit("setIndicateLoading", true)
        await instance.get(`/data/${fileKey}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-ms-version": "2021-08-06",
                //'Cache-Control': 'no-cache',
                //'Pragma': 'no-cache',
                //'Expires': '0'
            },
            responseType: "blob"
        }).then(function(response) {
            let blob = new Blob([response.data]);
            if (fileKey.toUpperCase().endsWith(".PDF")) {
                blob = new Blob([response.data], {type: "application/pdf"});
                window.open(URL.createObjectURL(blob));
            } else {
                // Force download for other files
                let a = document.createElement("a");
                document.body.appendChild(a);
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileKey.split("/").slice(-1)[0]
                a.click();
                window.URL.revokeObjectURL(url);
            }
            store.commit("setIndicateLoading", false)
            resolve();
        }).catch(function(error) {
            console.log(error);
            store.commit("setIndicateError", true)
            reject(error);
        })
    })
}

async function uploadFile(selectedYear, selectedMonth, selectedSSP, file) {
    let accessToken = localStorage.getItem("accessToken-storage");
    store.commit("setIndicateLoading", true)

    selectedSSP = selectedSSP.replace("/", "")
    await instance.put(`/data/userFiles/${selectedYear}/${selectedMonth}/${selectedSSP}/${file.name}`, file, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-ms-version": "2021-08-06",
            "x-ms-blob-type": "BlockBlob",
        },
        responseType: "blob"
    }).then(async function(response) {
        if(response.status === 201) {
            await loadFileData(selectedYear, selectedMonth, selectedSSP)
            store.commit("setIndicateLoading", false)
            store.commit("showSuccessAlert", `Datei ${file.name} wurde erfolgreich hochgeladen!`)
        } else {
            store.commit("setIndicateError", true)
            store.commit("showErrorAlert", `Datei ${file.name} konnte nicht hochgeladen werden!`)
        }
    }).catch(function(error) {
        console.log(error);
        store.commit("setIndicateError", true)
        store.commit("showErrorAlert", `Datei ${file.name} konnte nicht hochgeladen werden!`)
    })
}

async function deleteFile(selectedYear, selectedMonth, selectedSSP, fileName, retry = true) {
    let accessToken = localStorage.getItem("accessToken-storage");
    store.commit("setIndicateLoading", true)

    // Let user confirm we want to kill this one
    if (!confirm(`Warnung: Bist du sicher, dass du ${fileName} permanent löschen möchstest?`)) {
        return
    }

    selectedSSP = selectedSSP.replace("/", "")
    await instance.delete(`/data/userFiles/${selectedYear}/${selectedMonth}/${selectedSSP}/${fileName}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-ms-version": "2021-08-06",
            "x-ms-blob-type": "BlockBlob",
        },
        responseType: "blob"
    }).then(async function(response) {
        if(response.status === 202) {
            await loadFileData(selectedYear, selectedMonth, selectedSSP)
            store.commit("setIndicateLoading", false)
            store.commit("showSuccessAlert", `Datei ${fileName} wurde erfolgreich gelöscht!`)
        } else {
            store.commit("setIndicateError", true)
            store.commit("showErrorAlert", `Datei ${fileName} konnte nicht gelöscht werden!`)
        }
    }).catch(function(error) {
        console.log(error);
        store.commit("setIndicateError", true)
        store.commit("showErrorAlert", `Datei ${fileName} konnte nicht gelöscht werden!`)
    })
}

export default {
    loadFileData,
    loadFile,
    uploadFile,
    deleteFile
}