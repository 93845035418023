<template xmlns="http://www.w3.org/1999/html">
  <div class="flex flex-col">
    <span class="mt-4 font-bold	">Notizzettel {{ selectedSSP }}</span>
    <div class="flex flex-col my-1 mr-5 w-80 border-2 border-gray-400">
      <span id=userNoteSpan class="m-3 max-h-40 overflow-y-scroll text-sm text-left whitespace-pre" @keyup="onNoteUpdate" contenteditable>{{ $store.getters.getSellingSidePlatform(selectedSSP)?.ssp_userNote ?? ""}}</span>
      <div @click="updateUserNote" class="text-sm text-center border-t border-gray-400 hover:bg-gray-300 cursor-copy p-2">
        <font-awesome-icon icon="fa-upload" class="pr-3" />
        <span>Speichern</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/js/api";
export default {
  name: "Notepad",
  props: ["selectedSSP"],
  methods: {
    onNoteUpdate(event) {
      this.isChanged = true;
      this.userNote = document.getElementById("userNoteSpan").innerText
    },
    async updateUserNote() {
      if (!this.isChanged) {
        return;
      }
      await api.patchSellingSidePlatform(this.selectedSSP, this.userNote);
      await api.getSellingSidePlatform();
      this.userNote = "";
      this.isChanged = false;
      this.$store.commit("showSuccessAlert", "Notiz gespeichert!")
    }
  },
  data() {
    return {
      userNote: "",
      isChanged: false
    }
  }
}
</script>